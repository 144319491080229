<template>
    <div class="lucky-bag">
        <div class="inner-content">
            <div class="create-lucky-bag">
                <button class="create-bag-button" @click="createBag">
                    <i class="iconfont">&#xe68f;</i>
                    <span class="bag-word">创建福袋</span>
                </button>
            </div>
            <div class="lucky-bag-list">
                <el-table
                    :data="tableData"
                    :header-cell-style="{height: '60px', color: '#fff', background: '#0C0E3F', fontSize: '14px', fontWeight: '400', border: '1px solid transparent'}"
                    :cell-style="{height: '80px', color: '#fff', background: '#070932', fontSize: '14px', fontWeight: '400', }">
                    <el-table-column prop="bag_name" label="福袋名称" width="200"></el-table-column>
                    <el-table-column prop="winning_number" label="可中奖人数"></el-table-column>
                    <el-table-column prop="in_type_name" label="参与范围" width="180"></el-table-column>
                    <el-table-column prop="countdown_minSec" label="倒计时"></el-table-column>
                    <el-table-column prop="command_name" label="口令" width="200"></el-table-column>
                    <el-table-column label="操作" align="center" width="150">
                        <template slot-scope="scope">
                            <div class="link-list">
                                <el-link
                                    type="primary"
                                    :underline="false"
                                    @click="editLuckyBag(scope.row)">编辑</el-link>
                                <el-link
                                    type="danger"
                                    :underline="false"
                                    @click="deleteLuckyBag(scope.row)">删除</el-link>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </div>
</template>

<script>
import { luckyBagList, deleteLuckyBag } from '@/utils/apis'

export default {
    name: "LuckyBag",
    data() {
        return {
            tableData: []
        };
    },
    computed: {},
    components: {},
    methods: {
        init() {
            this.luckyBagData()
        },
        async luckyBagData() {
            let res = await luckyBagList({ paging: 0 });
            console.log(res.data);
            let tableList = res.data.list;
            let min = 0, sec = 0;
            let countdown_time = {};
            for (let x = 0; x < tableList.length; x ++) {
                if (tableList[x]['countdown'] <= 60) {
                    sec = tableList[x]['countdown'] + '秒'
                    countdown_time.countdown_minSec = sec;
                    Object.assign(tableList[x], countdown_time);
                } else {
                    min = parseInt(tableList[x]['countdown'] / 60);
                    sec = tableList[x]['countdown'] % 60;
                    countdown_time.countdown_minSec = `${min}分${sec}秒`;
                    Object.assign(tableList[x], countdown_time);
                };
                if (tableList[x]['in_type'] == 1) {
                    countdown_time.in_type_name = '全民福袋';
                    Object.assign(tableList[x], countdown_time);
                } else if (tableList[x]['in_type'] == 2) {
                    countdown_time.in_type_name = '粉丝团福袋';
                    Object.assign(tableList[x], countdown_time);
                }
            };
            this.tableData = res.data.list;
        },
        createBag() {
            this.$router.push({
                path: '/liveMarketing/luckyBagCreat',
                name: "luckyBagCreate",
            })
        },
        editLuckyBag(row) {
            this.$router.push({
                path: '/liveMarketing/LuckyBagEdit',
                name: "LuckyBagEdit",
                query: {bag_id: row.lucky_bag_student_id}
            })
        },
        deleteLuckyBag(row) {
            this.$confirm('是否确定删除该福袋，删除后将无法恢复', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                customClass: 'lucky-bag-dialog',
                type: 'warning'
                }).then(async () => {
                    let res = await deleteLuckyBag({ lucky_bag_student_id: row.lucky_bag_student_id })
                    if (res.code == 200) {
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                        this.luckyBagData()
                    } else {
                        this.$message.error(res.msg);
                    }
                    
                })
        }
    },
    mounted() {
        this.init()
    },
};
</script>

<style scoped lang="scss">
    .lucky-bag {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    .inner-content {
        margin: 20px;
        flex: 1;
        height: 1%;
        display: flex;
        flex-direction: column;
        position: relative;
    }
    .create-lucky-bag {
        width: 178px;
        height: 94px;

        .create-bag-button {
            width: 178px;
            height: 100%;
            color: #fff;
            font-size: 14px;
            font-weight: 400;
            border: 0;
            background: url('../../../../assets/image/studentCreateBag/createBagButton.png');
            cursor: pointer;
        }
        .iconfont {
            display: block;
            width: 40px;
            height: 39px;
            margin: 10px 0 8px 60px;
            font-size: 36px;
        }
    }
    .lucky-bag-list {
        height: 1%;
        flex: 1;
        margin-top: 22px;
    }

    .el-table {
        position: absolute;
        width: 100%;

        &:before {
            content: none;
        }

        ::v-deep.el-table__empty-block {
            background: #070932;

            .el-table__empty-text {
                color: #fff;
            }
        }

        ::v-deep .el-table__body-wrapper {
            background: #070932;
        }


        ::v-deep th.el-table__cell.is-leaf {
            border-bottom: none;
        }

        ::v-deep td.el-table__cell {
            border-bottom: 1px solid #262963;

            &:first-child {
                border-left: 1px solid #262963;
            }

            &:last-child {
                border-right: 1px solid #262963;
            }
        }
    }
    ::v-deep .el-table--enable-row-hover .el-table__body tr:hover>td{
        background-color: #0C0E3F !important;
    }
</style>

<style>
    .lucky-bag-dialog {
        width: 500px;
        height: 240px;
        border: 1px solid transparent;
        background: #0C0E3F;
    }
    .lucky-bag-dialog .el-message-box__header {
        padding: 0;
        margin: 17px 0 0 23px;
    }
    .lucky-bag-dialog .el-message-box__title {
        color: #fff;
        font-size: 16px;  
    }
    .lucky-bag-dialog .el-message-box__title::before {
        display: inline-block;
        content: "";
        width: 4px;
        height: 12px;
        margin-right: 10px;
        background-color: #2821fc;
    }
    .lucky-bag-dialog .el-message-box__headerbtn {
        top: 0;
        right: 24px;
        width: 14px;
        height: 14px;
        font-size: 14px;
    }
    .lucky-bag-dialog .el-message-box__headerbtn .el-message-box__close {
        color: #fff;
    }
    .lucky-bag-dialog .el-message-box__headerbtn .el-message-box__close:hover {
        color: #fff;
    }
    .lucky-bag-dialog .el-message-box__content {
        padding: 0;
        margin: 79px 0 57px 77px;
        color: #fff;
    }
    .lucky-bag-dialog .el-message-box__status.el-icon-warning {
        width: 16px;
        height: 16px;
        color: #F39800;
        font-size: 16px!important;
    }
    .lucky-bag-dialog .el-message-box__message {
        margin: 0 0 0 -11px;
    }
    .lucky-bag-dialog.el-message-box__btns {
        height: 36px;
        padding: 0;
        margin-top: 37px;
        text-align: center;
    }
    .lucky-bag-dialog .el-button {
        width: 80px;
        height: 36px;
        background: #0C0E3F;
        border: 1px solid #262963;
        border-radius: 4px;
    }
    .lucky-bag-dialog .el-button:hover {
        background: #0C0E3F;
        border: 1px solid #262963;
        color: #999999;
    }
    .lucky-bag-dialog .el-message-box__btns button:nth-child(2) {
        color: #fff;
        background: #4B45FF;
    }
    .lucky-bag-dialog .el-message-box__btns button:nth-child(2):hover {
        color: #fff;
        background: #4B45FF;
    }
    .lucky-bag-dialog .el-button--small {
        font-size: 14px;
        font-weight: 400;
        color: #999999;
    }
</style>
